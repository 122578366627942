import Header from './header';
import MintComponent from './MintComponent';
import { React, useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section1b from './Section1b';
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function App() {
  gsap.registerPlugin(ScrollTrigger);

  let ref = useRef();

  let home = useRef();
  let homeb = useRef();

  let chain = useRef();
  let faq = useRef();
  let links = useRef();

  useEffect(() => {
    const el = ref.current;
    gsap.fromTo(
      el,
      { x: -200 },
      { x: 0, duration: 3, scrollTrigger: { trigger: el } }
    );
  }, []);
  useEffect(() => {
    const el = home.current;
    gsap.fromTo(
      el,
      { x: -200 },
      { x: 0, duration: 3, scrollTrigger: { trigger: el } }
    );
  }, []);
  useEffect(() => {
    const el = homeb.current;
    gsap.fromTo(
      el,
      { x: -200 },
      { x: 0, duration: 3, scrollTrigger: { trigger: el } }
    );
  }, []);
  useEffect(() => {
    const el = chain.current;
    gsap.fromTo(
      el,
      { x: -200 },
      { x: 0, duration: 3, scrollTrigger: { trigger: el } }
    );
  }, []);

  useEffect(() => {
    const el = faq.current;
    gsap.fromTo(
      el,
      { x: -200 },
      { x: 0, duration: 3, scrollTrigger: { trigger: el } }
    );
  }, []);
  useEffect(() => {
    const el = links.current;
    gsap.fromTo(
      el,
      { x: -200 },
      { x: 0, duration: 3, scrollTrigger: { trigger: el } }
    );
  }, []);
  /*
  ScrollTrigger.defaults({
    toggleActions: 'restart pause resume pause',
    scroller: '.maindiv',
  });

  /*
  useEffect(() => {
    gsap.fromTo(
      '.section1',
      { x: -200, duration: 2 },
      {
        x: 0,
        duration: 1,
        ease: 'powerOut',
        toggleActions: 'restart pause reverse pause',
      }
    );
    gsap.fromTo(
      '.section2',
      { x: -200, duration: 2 },
      {
        x: 0,
        duration: 1,
        ease: 'powerOut',
        toggleActions: 'restart pause reverse pause',
      }
    );
    gsap.fromTo(
      '.section3',
      { x: -200, duration: 2 },
      {
        x: 0,
        duration: 1,
        ease: 'powerOut',
        toggleActions: 'restart pause reverse pause',
      }
    );
  }, []);*/
  const handleTop = () => scrollToRef(ref);

  const handleHome = () => scrollToRef(home);
  const handleChain = () => scrollToRef(chain);
  const handleFaq = () => scrollToRef(faq);
  const handleLinks = () => scrollToRef(links);

  return (
    <div className='bg-cgray   '>
      <header className='md:flex   w-full sticky top-0 z-50   text-center justify-between  bg-cgray  border-b-2 p-2 border-cgray'>
        <a className='   '>
          <img src='./Logo.png ' className='w-44 h-15 mx-auto ' />
        </a>
        <nav
          className={`    transition-all duration-200  flex justify-around gap-x-8 text-cblue flex-wrap   p-3 
 
          `}
        >
          <button onClick={handleHome} className=' hover:text-cpink'>
            <a>Holder Benefits</a>
          </button>
          <button onClick={handleChain} className=' hover:text-cpink'>
            <a>Multichain Breeding</a>
          </button>
          <button onClick={handleFaq} className=' hover:text-cpink'>
            <a> FAQ</a>
          </button>
          <button className=' hover:text-cpink'>
            <a href='./wifepaper.pdf' target='_blank' rel='noreferrer'>
              {' '}
              Wifepaper
            </a>
          </button>
          <button onClick={handleLinks} className=' hover:text-cpink'>
            <a>Links</a>
          </button>
        </nav>
        <a className='  '>
          <img src='./Morsel-Protocol.png' className='w-74 h-14 mx-auto' />
        </a>
      </header>
      <div className='maindiv'>
        <div className='md:flex justify-between first section1   ' ref={ref}>
          <MintComponent />
          <iframe
            className='md:w-2/4 w-full  '
            src='https://www.youtube.com/embed/Zi8L8E2o5z0'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </div>
        <div ref={home} className='section2  '>
          <Section1 />
        </div>{' '}
        <button
          onClick={handleTop}
          className='text-center mx-auto w-full underline hover:text-cpink'
        >
          <a>Back To Top</a>
        </button>
        <div ref={homeb} className='section2b  '>
          <Section1b />
        </div>
        <button
          onClick={handleTop}
          className='text-center mx-auto w-full underline hover:text-cpink'
        >
          <a>Back To Top</a>
        </button>
        <div ref={chain} className='section3  '>
          <Section2 />
        </div>{' '}
        <button
          onClick={handleTop}
          className='text-center mx-auto w-full underline hover:text-cpink'
        >
          <a>Back To Top</a>
        </button>
        <div ref={faq} className='section4  '>
          <Section3 />
        </div>{' '}
        <button
          onClick={handleTop}
          className='text-center mx-auto w-full underline hover:text-cpink'
        >
          <a>Back To Top</a>
        </button>
        <div ref={links} className='section5  '>
          <Section4 />
        </div>
      </div>
    </div>
  );
}

export default App;
