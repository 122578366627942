export default function Section1b() {
  return (
    <div className=' py-24 bg-cgray container mx-auto  '>
      <h1 className=' my-8 max-w-2xl sm:px-6 text-cpink   font-extrabold   mb-8 px-4 text-center  mx-auto text-4xl lg:max-w-6xl lg:px-12  '>
        Giveaway Benefits (Chance To Win) <br></br>
      </h1>
      <table class=' w-full  table-fixed   '>
        <thead className='bg-cblue text-xl text-center text-white'>
          <tr className='    '>
            <th className='p-2 w-1/4'>Range</th>
            <th className='p-2 '>Giveaway Benefits (Chance To Win)</th>
          </tr>
        </thead>
        <tbody className='bg-cpink   text-center text-white'>
          <tr className=' border-b'>
            <td className='bg-cpink p-2 '>Every Mint</td>
            <td className='bg-cpink p-2 text-sm'>5 Gen0 Whales + 5000 USDC</td>
          </tr>
          <tr className=' border-b'>
            <td className='bg-cpink p-2 '>First 2500</td>
            <td className='bg-cpink p-2 text-sm'>
              Beachfront CU Land + 3 Wizards
            </td>
          </tr>
          <tr className=' border-b'>
            <td className='bg-cpink p-2 '>First 2000</td>
            <td className='bg-cpink p-2 text-sm'>
              10 Dingos + Cosmic Universe (CU) Land
            </td>
          </tr>
          <tr className=' border-b'>
            <td className='bg-cpink p-2 '>First 1500</td>
            <td className='bg-cpink p-2 text-sm'>
              2X Tranquility City Apartments, Chibis, Dingos, Gen0 Azans,
              Reflectaverse Badges and Gen0 Whales
            </td>
          </tr>
          <tr className=' border-b'>
            <td className='bg-cpink p-2 '>First 1000</td>

            <td className='bg-cpink p-2 text-sm'>
              10 Dingos + Tranquility City Apartment
            </td>
          </tr>
          <tr className=' border-b'>
            <td className='bg-cpink p-2 '>First 500</td>
            <td className='bg-cpink p-2 text-sm'>
              Mystic Aipine CU Land + OG Chibi Cat + 2 K&P Knights & Much More
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
