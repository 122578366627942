export default function Section2() {
  return (
    <div className=' py-24 bg-cgray container mx-auto  '>
      <h1 className='  max-w-2xl sm:px-6 text-cblue   font-extrabold   mb-8 px-4 text-center  mx-auto text-4xl lg:max-w-6xl lg:px-12  '>
        MultiChain Breeding <br></br>
      </h1>

      {/* <table>
        <thead>
          <tr></tr>
          <tr></tr>
          <tr></tr>
        </thead>
        <tbody>
          <td>
            {' '}
            <div className='text-xl'>
              <img src='./ma.png' className='w-20 h-20' />{' '}
              <h1 className='text-md'> Harmony</h1>
            </div>
          </td>
          <td> </td>

          <td>
            <div className='  '>
              <img src='./m2.png' className='w-20 inline-flex rounded-full  ' />
              <h1 className='text-md inline-flex'> Royalities</h1>
            </div>{' '}
          </td>
        </tbody>
      </table>*/}

      <div className='flex flex-col md:grid grid-cols-9 mx-auto p-2 text-black'>
        <div className='flex flex-row-reverse md:contents'>
          <div className='   border-2 border-cpink col-start-1 col-end-5  w-full rounded-md my-4 ml-auto shadow-md'>
            <div className='    h-full  my-4  '>
              <div className='flex border-b border-cblue  text-center justify-around mx-auto'>
                <div>
                  <img src='./ma.png' className='w-20 h-20 mx-auto ' />{' '}
                  <h1 className='text-md'> Harmony</h1>
                </div>{' '}
                <img src='./m1.png' className='w-24 h-24   rounded-full' />
              </div>

              <div className='flex-col justify-around text-center mx-auto'>
                <div>
                  <img src='./dollar1.png' className='w-20 h-20 mx-auto' />
                  <h1 className='text-sm'> Royalities</h1>
                </div>{' '}
                <div>
                  <img src='./logo512.png' className='w-20 h-20 mx-auto' />
                  <h1 className='text-sm'>
                    {' '}
                    MORSEL Staking Rewards-Earn Fees from Harmony Apps
                  </h1>
                </div>{' '}
                <div>
                  <img src='./dollar.png' className='w-20 h-20 mx-auto' />
                  <h1 className='text-sm'> Dividends</h1>
                </div>
              </div>
            </div>
          </div>
          <div className='col-start-5 col-end-6 md:mx-auto relative mr-10'>
            <div className='h-full w-6 flex items-center justify-center'>
              <div className='h-full w-1 bg-cblue pointer-events-none'></div>
            </div>
            <div className='w-6 h-6 absolute top-1/2 -mt-3 rounded-full border-2 bg-cpink border-white shadow'></div>
          </div>
        </div>
        <div className='flex md:contents'>
          <div className='col-start-5 col-end-6 mr-10 md:mx-auto relative'>
            <div className='h-full w-6 flex items-center justify-center'>
              <div className='h-full w-1 bg-cblue pointer-events-none'></div>
            </div>
            <div className='w-6 h-6 absolute top-1/2 -mt-3 rounded-full border-2  bg-cpink border-white shadow'></div>
          </div>
          <div className='border-2 border-cpink col-start-6 col-end-10   w-full  rounded-md mr-auto shadow-md'>
            <div className='    h-full  my-4   '>
              <div className='flex border-b border-cblue text-center justify-around mx-auto'>
                <div>
                  <img src='./m4.png' className='w-20 h-20 mx-auto ' />{' '}
                  <h1 className='text-md'> AVAX</h1>
                </div>{' '}
                <img src='./m3.png' className='w-24 h-24   rounded-full' />
              </div>

              <div className='flex-col justify-around text-center mx-auto'>
                <div>
                  <img src='./m2.png' className='w-20 h-20 mx-auto' />
                  <h1 className='text-sm'>
                    {' '}
                    Nibble Staking Rewards- Earn Fees from AVAX dAPPs
                  </h1>
                </div>{' '}
                <div>
                  <img src='./dollar.png' className='w-20 h-20 mx-auto' />
                  <h1 className='text-sm'>Dividends</h1>
                </div>{' '}
                <div className='bg-cblue mt-8 p-2 text-white'>
                  <h1 className='text-md'>
                    {' '}
                    Match Maker AIRDROP: For every two TradWifes you hold on
                    Harmony, an AVAX chain Lumberjax will be airdropped to same
                    address
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row-reverse md:contents'>
          <div className='border-2 border-cpink col-start-1 col-end-5 w-full ml-auto rounded-md shadow-md'>
            <div className='    h-full  my-4 text-center w-full'>
              {' '}
              <img src='./ftm.png' className='w-20 h-20 mx-auto ' />{' '}
              <div className='flex border-b border-cblue mt-2  justify-around mx-auto'>
                <div className='bg-cpink p-2 text-white border-r w-full'>
                  <h1 className='text-md'>
                    {' '}
                    Fantom NFT <br></br> (Full Allocation)
                  </h1>
                </div>{' '}
                <div className='bg-cpink p-2 text-white w-full'>
                  <h1 className='text-md'>
                    {' '}
                    Fantom Baby NFT <br></br>(Smaller Allocation)
                  </h1>
                </div>{' '}
              </div>
              <div className='flex justify-around gap-x-2  mx-auto'>
                <div className='flex  mt-8  flex-col '>
                  <div className=' '>
                    <img src='./donut.png' className='w-10 h-10 mx-auto ' />
                    <h1 className='text-sm mb-8'>
                      MUNICH Staking Rewards Earn Fees from FTM dAPPs
                    </h1>
                  </div>
                  <div className=' '>
                    <img src='./dollar.png' className='w-10 h-10 mx-auto ' />
                    <h1 className='text-sm'>Dividends</h1>{' '}
                  </div>
                </div>
                <div className='flex  mt-8  flex-col '>
                  <div className=' '>
                    <img src='./donut.png' className='w-10 h-10 mx-auto ' />
                    <h1 className='text-sm mb-8'>MUNICH Staking Rewards</h1>
                  </div>
                  <div className=' '>
                    <img src='./dollar.png' className='w-10 h-10 mx-auto ' />
                    <h1 className='text-sm'>
                      Baby Shower Gifts <br></br>
                      (Cards that give Rewards from Ecosystem Partners)
                    </h1>
                  </div>
                </div>
              </div>
              <div className='bg-cblue mt-8 p-2 text-white'>
                <h1 className='text-sm'>
                  After the first two chains, chains 3+ will have two Stakable
                  NFTs... That chain's main NFT for our product suite and a Baby
                  that offers a smaller allocation.
                </h1>
                <h1 className='text-sm'>
                  Each TradWife + Lumberjax you hold will be able to breed a
                  baby for the 3rd, 4th, 5th, etc chain.
                </h1>
              </div>
            </div>
          </div>
          <div className='col-start-5 col-end-6 md:mx-auto relative mr-10'>
            <div className='h-full w-6 flex items-center justify-center'>
              <div className='h-full w-1 bg-cblue pointer-events-none'></div>
            </div>
            <div className='w-6 h-6 absolute top-1/2 -mt-3 rounded-full border-2 bg-cpink border-white shadow'></div>
          </div>
        </div>
        <div className='flex md:contents'>
          <div className='col-start-5 col-end-6 mr-10 md:mx-auto relative'>
            <div className='h-full w-6 flex items-center justify-center'>
              <div className='h-full w-1 bg-cblue pointer-events-none'></div>
            </div>
            <div className='w-6 h-6 absolute top-1/2 -mt-3 rounded-full border-2 bg-cpink border-white  shadow'></div>
          </div>
          <div className='border-2 border-cpink col-start-6 col-end-10 w-full  mr-auto shadow-md'>
            <img src='./chain.png' className='w-20 h-20 mx-auto m-2 ' />

            <h3 className='  p-8 m-2 tracking-widest text-xl bg-cblue  text-center text-white '>
              Chain 4
            </h3>
          </div>
        </div>
        <div className='flex flex-row-reverse md:contents'>
          <div className='  border-2 border-cpink col-start-1 col-end-5  w-full  ml-auto shadow-md'>
            <img src='./chain.png' className='w-20 h-20 mx-auto m-2 ' />

            <h3 className='  p-8 m-2 tracking-widest text-xl bg-cblue  text-center text-white '>
              Chain 5
            </h3>
          </div>
          <div className='col-start-5 col-end-6 md:mx-auto relative mr-10'>
            <div className='h-full w-6 flex items-center justify-center'>
              <div className='h-full w-1 bg-cblue pointer-events-none'></div>
            </div>
            <div className='w-6 h-6 absolute top-1/2 -mt-3 rounded-full border-2 bg-cpink border-white  shadow'></div>
          </div>
        </div>{' '}
        <div className='flex md:contents'>
          <div className='col-start-5 col-end-6 mr-10 md:mx-auto relative'>
            <div className='h-full w-6 flex items-center justify-center'>
              <div className='h-full w-1 bg-cblue pointer-events-none'></div>
            </div>
            <div className='w-6 h-6 absolute top-1/2 -mt-3 rounded-full border-2 bg-cpink border-white  shadow'></div>
          </div>
          <div className='border-2 border-cpink col-start-3 col-end-8 w-full p-4 rounded-md my-4 mr-auto shadow-md'>
            <img src='./chain.png' className='w-20 h-20   mx-auto m-2 ' />

            <h3 className='  p-8 m-2 tracking-widest text-xl bg-cblue  text-center text-white '>
              Future Chains{' '}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
