export default function Section1() {
  return (
    <div className=' py-24 bg-cgray container mx-auto  '>
      <h1 className='  max-w-2xl sm:px-6 text-cblue   font-extrabold   mb-8 px-4 text-center  mx-auto text-4xl lg:max-w-6xl lg:px-12  '>
        Holder Benefits <br></br>
      </h1>

      <h1 className='  max-w-2xl sm:px-6 text-cpink   font-extrabold   mb-8 px-4 text-center  mx-auto text-4xl lg:max-w-6xl lg:px-12  '>
        Primary Benefits <br></br>
      </h1>
      <div className='grid lg:grid-cols-3 mx-auto items-center justify-center text-center gap-8 '>
        <div className='   border-2 border-cpink    my-4   shadow-md'>
          <h3 className='font-bold bg-cblue text-white  p-4 tracking-widest text-md mb-4 '>
            NFT Staking
          </h3>
          <h3 className=' h-20 p-4 tracking-widest text-md mb-4 lg:w-96'>
            16,800 MORSEL, -$966 Based on IDO Price (Projection-only)
          </h3>
        </div>
        <div className='   border-2 border-cpink    my-4   shadow-md'>
          <h3 className='font-bold bg-cblue text-white  p-4 tracking-widest text-md mb-4 '>
            Royalities
          </h3>
          <h3 className=' h-20 p-4 tracking-widest text-md mb-4 lg:w-96'>
            100% Re-Sale Royalities Airdropped Until NFT Staking{' '}
          </h3>
        </div>
        <div className='   border-2 border-cpink    my-4   shadow-md'>
          <h3 className='font-bold bg-cblue text-white  p-4 tracking-widest text-md mb-4 '>
            Dividends
          </h3>
          <h3 className=' h-20 p-4 tracking-widest text-md mb-4 lg:w-96'>
            Payouts Begin after NFT Staking ends
          </h3>
        </div>
        <div className='   border-2 border-cpink    my-4   shadow-md'>
          <h3 className='font-bold bg-cblue text-white  p-4 tracking-widest text-md mb-4 '>
            Matchmaker Airdrop
          </h3>
          <h3 className=' h-20 p-4 tracking-widest text-md mb-4 lg:w-96'>
            Every 2x TradWifes get 1 AVAX NFT Airdrop + More Staking{' '}
          </h3>
        </div>
        <div className='   border-2 border-cpink    my-4   shadow-md'>
          <h3 className='font-bold bg-cblue text-white  p-4 tracking-widest text-md mb-4 '>
            Breeding
          </h3>
          <h3 className=' h-20 p-4 tracking-widest text-md mb-4 lg:w-96'>
            Future Chain Allocations{' '}
          </h3>
        </div>
      </div>
    </div>
  );
}
