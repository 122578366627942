export default function Section3() {
  return (
    <div className=' py-24  container mx-auto  '>
      <h1 className='  max-w-2xl sm:px-6 text-cblue   font-extrabold   mb-8 px-4 text-center  mx-auto text-4xl lg:max-w-6xl lg:px-12  '>
        FAQs <br></br>
      </h1>
      <div className=' mx-auto  px-4   items-center   gap-y-16   '>
        <div className='w-full  border-2 border-cpink rounded-sm  py-6 px-4 '>
          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              What is Morsel Protocol?{' '}
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  Morsel Protocol is a set of products that are designed to lock
                  liquidity into Harmony ONE projects. These products enable
                  signaling of support to neighboring projects and their
                  investors. OneBite is an index fund that can be held in
                  project treasuries to gain diversified exposure to Harmony ONE
                  protocols while signaling that they support the ecosystem.
                  Wifely Internet Money (WIM), a stablecoin that is
                  collateralized by OneBite, can similarly be used in Liquidity
                  Pairs to signal the same support.
                </p>
              </div>
            </span>
          </details>
          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              What is OneBite?{' '}
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  OneBite is an Index fund offered by Morsel Protocol that
                  passively generates yields from lending, liquidity mining,
                  vaults, staking, initial DEX offerings and even arbitration
                  opportunities within the Harmony One ecosystem (providing
                  reliable Beta).
                </p>
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              What is Wifely Internet Money (WIM)?
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  WIM is the world’s first Companion Stablecoin. Its adoption
                  will support projects throughout the Harmony ONE ecosystem.
                </p>
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              What is a Companion Stablecoin?{' '}
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  The term refers to an <b>over-collateralized Stablecoin</b> that
                  supports a particular ecosystem. Since it locks up capital in
                  local protocols, it is a good “companion”. Our vision is that
                  adoption of Companion Stablecoins will become a new standard
                  throughout crypto as a way for projects to <b>signal support</b> by
                  using it in their Liquidity Pairs.<br /><br /> After all, why waste
                  support on Coinbase or Binance when your choice in Stablecoin
                  can generate goodwill and attract investors from other
                  projects?
                </p>
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              How will development be funded?
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  Morsel Protocol will initially fund its development through an
                  IMO (Initial Mint Offering), selling 3000 TradWife NFTs. A
                  secondary fundraising round will be scheduled at a future date
                  offering 7.5% of the MORSEL supply to IDO participants.
                </p>
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              How will raised funds be deployed?
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  Our Treasury will take a conservative approach and raised
                  funds will be mostly re-invested. Development will be funded,
                  on a shoestring basis, off the EARNINGS from those
                  investments. This will largely preserve the raised capital and
                  make Morsel Protocol bear-market resistant (as we will ALWAYS
                  be able to continue funding development).
                </p>
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              What is a TradWife?
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  TradWifes are our OG NFT collection that is inspired by
                  Proverbs 31. In that proverb, it describes a capable wife who
                  is skilled in investments, management, farming, trading,
                  saving and is generally hard working. Our TradWife NFT will do
                  all this for you passively by generating MORSEL, with 35% of
                  the total supply set aside for holders from staking alone!
                  MORSEL earns fees when the TradWifes cook up OneBite (an index
                  fund where they diversify investor funds). Our intention is
                  for early investors to gain majority control of the MORSEL
                  token supply. Beyond Harmony, TradWife NFTs will bring rewards
                  for every subsequent interlocking project we launch.
                </p>
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              What can a TradWife NFT do for you?
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <img src='./pic1.jpg' className='mx-auto h-96 w-auto ' />
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              What is MORSEL?{' '}
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  MORSEL token is earned primarily by <b>staking TradWife NFTs</b>. It can be
                  held, made into liquidity pairs or banked as <b>xMORSEL</b> (our
                  fee-earning governance token). <br /><br />Banking is its primary utility
                  where it will <b>earn fees</b> from OneBite minting (and later
                  additionally from WIM <b>stablecoin minting and liquidations</b>). MORSEL may
                  also play a role when TradWife NFTs start making babies.
                </p>
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              What about Tokenomics? MORSEL Wifenomics?
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <img src='./pic2.png' className='mx-auto h-96 w-auto ' />
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              OneBite Tokenomics?{' '}
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>● Uncapped Supply</p>
                <p className='text-md  text-justify'>
                  ● Minted/Burned as capital enters/leaves the MORSEL dApp
                </p>
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              Will There Be a Game?{' '}
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  We are one of those rare protocols on Harmony ONE that is NOT
                  a game. That said, we are already forming partnerships with
                  multiple games throughout the ecosystem and will have some
                  presence in Tranquility City and Meta-Humanoids (as examples).
                  We have lots of ideas rolling around but, at this point, it is
                  best to view TradWifes and MORSEL as your favorite non-gaming
                  Harmony ONE plays.
                </p>
              </div>
            </span>
          </details>

          <details className='mb-4'>
            <summary className='font-semibold cursor-pointer bg-cblue text-white rounded-md py-4 px-4'>
              Other Expansion plans?{' '}
            </summary>

            <span>
              <div className=' rounded-sm  py-6 px-4  '>
                <p className='text-md  text-justify'>
                  After we have proven the concept of our suite of products on
                  Harmony ONE, we will launch satellite Protocols on additional
                  blockchains. Each additional ecosystem will provide an
                  opportunity to reward our OG Harmony ONE investors.
                </p>
              </div>
            </span>
          </details>
        </div>
      </div>
    </div>
  );
}
