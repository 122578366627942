export default function Section4() {
  return (
    <div className=' py-24  container mx-auto  '>
      <div className=' mx-auto  p-4   bg-cblue md:flex justify-between rounded-md  my-auto  '>
        <div className='text-white flex flex-col  justify-between'>
          <h1 className='text-4xl'>Join the Community</h1>
          <h1 className='text-md text-cgray mt-2'>
            Join us to get the news as soon as possible and follow our latest
            announcements
          </h1>
          <div className='flex gap-x-8  my-8 '>
            
            <a
              className=' cursor-pointer'
              href='https://discord.gg/23bydrcvpv'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='./d.png' className='w-12' />
            </a>
            <a
              className=' cursor-pointer'
              href='https://twitter.com/TradWifeNFT'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='./c.png' className='w-12' />
            </a>
            <a
              className=' cursor-pointer'
              href='https://morsel-protocol.gitbook.io/morsel-protocol/C2n5D3itJDis3ScMp6X9/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='./a.png' className='w-12' />
            </a>
            <a
              className=' cursor-pointer'
              href='https://medium.com/@tradwifes/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='./b.png' className='w-12' />
            </a>
            <a
              className=' cursor-pointer'
              href='https://twitter.com/morselprotocol'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='./c.png' className='w-12' />
            </a>

            <a
              className=' cursor-pointer'
              href='https://www.youtube.com/channel/UCIXMqjDxpJ-uSx5f9sPLWyg'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='./e.png' className='w-12' />
            </a>
          </div>
        </div>
        <div className='my-auto  '>
          <a
            className='bg-cgray px-4 py-2 rounded-md text-cblue text-xl cursor-pointer'
            href='https://discord.gg/23bydrcvpv'
            target='_blank'
            rel='noopener noreferrer'
          >
            Join Our Discord
          </a>
        </div>
      </div>
    </div>
  );
}
