import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from './redux/blockchain/blockchainActions';
import { fetchData } from './redux/data/dataActions';
import i1 from './assets/images/1.png';
import Select from 'react-select';
function MintComponent() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Isn't it great to find the ONE!");
  const [claimingNft, setClaimingNft] = useState(false);

  const [selectedClient, setSelectedClient] = useState('2');

  function handleSelectChange(event) {
    setSelectedClient(event.target.value);
  }
  console.log(selectedClient);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback('Minting your TradWife...');
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(_amount)
      .send({
        to: '0x3b846cfee932c365273767aa4a5683f8187f6b60',
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((1000 * _amount).toString(), 'ether'),
      })
      .once('error', (err) => {
        console.log(err);
        setFeedback('Sorry, something went wrong please try again later.');
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          'You are now joined in holy hashimony with a supportive TradWife. Go visit NFTKey.app to view it.'
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div
    /*    className='  bg-no-repeat bg-contain mx-auto text-center w-full bg-auto '
      style={{
        backgroundImage: "url('./1.png')",
      }}*/
    >
      <div className='bg-cblue text-white text-center '>
        <div className=' '>
          <div className=' '>
            {/*   <img alt={'example'} src={i1} className='w-96 mx-auto  h-96' />8/*/}
            <p className=' text-white py-8 font-bold text-4xl text-center'>
              {data.totalSupply}/3000
            </p>
          </div>{' '}
          <div className='text-sm my-8 '>
            {Number(data.totalSupply) === 3000 ? (
              <>
                <p>The sale has ended.</p>
                <p>
                  You can still find TRADWIFES on{' '}
                  <a
                    target={'_blank'}
                    rel='noreferrer'
                    href={'https://nftkey.app/collection/tradwifes'}
                  >
                    Opensea.io
                  </a>
                </p>
              </>
            ) : (
              <>
                <p className='text-cpink text-4xl font-extrabold '>
                  1 TRADWIFE costs 1000 ONE.
                </p>
                <p className='mt-8 text-xs text-cgray'> Excluding gas fee.</p>
                <p className='text-xs text-cgray'>{feedback}</p>
                {blockchain.account === '' ||
                blockchain.smartContract === null ? (
                  <div>
                    <p className='text-xs text-cgray'>
                      Connect to the Harmony ONE network
                    </p>
                    <button
                      className='bg-cpink  text-white px-8 py-2 my-8 text-2xl'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </button>
                    {blockchain.errorMsg !== '' ? (
                      <>
                        <p>{blockchain.errorMsg}</p>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className='my-8'>
                        <label
                          className='select text-lg text-cpink mr-2'
                          for='slct'
                        >
                          Quantity of Wifes?
                        </label>
                        <select
                          id='slct'
                          className='bg-cpink text-lg'
                          required='required'
                          value={selectedClient}
                          onChange={handleSelectChange}
                        >
                          <option value='' disabled='disabled'>
                            SELECT TRADWIFE
                          </option>
                          <option value='1'>1 </option>
                          <option value='2' selected='selected'>2 </option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                          <option value='6'>6</option>
                          <option value='7'>7</option>
                          <option value='8'>8</option>
                          <option value='9'>9</option>
                          <option value='10'>10</option>
                          <option value='11'>11 </option>
                          <option value='12'>12</option>
                          <option value='13'>13</option>
                          <option value='14'>14</option>
                          <option value='15'>15</option>
                          <option value='16'>16</option>
                          <option value='17'>17</option>
                          <option value='18'>18</option>
                          <option value='19'>19</option>
                          <option value='20'>20</option>
                        </select>
                      </div>
                      <div>
                        <button
                          className='bg-cpink  text-white px-8 py-2   text-2xl'
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs(selectedClient);
                            getData();
                          }}
                        >
                          {claimingNft ? 'BUSY' : 'MINT'}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className='text-xs p-2 bg-cpink'>
          <p>
          <a href='https://nftkey.app/collections/tradwifes/'>See your TradWifes on NFTKey</a>!
            <br /><br />
            Please make sure you are connected to the right network (Harmony
            Mainnet) and the correct address. Please note: Once you make the
            purchase, you cannot undo this action.
          </p>
          
        </div>
      </div>
    </div>
  );
}

export default MintComponent;
